.attraction-details-page-image {
    width: 100%;
    height: 410px;
}
.attraction-details-page-image img {
    width: 100%;
    height: 100%; 
    object-fit: cover; 
    object-position: center; 
    image-rendering: -webkit-optimize-contrast; 
    image-rendering: pixelated; 
}

.attraction-details-page {
    padding: 2rem 4rem;
    margin-bottom: 2rem;
}
.attraction-details-page .inclusion {
    padding: 1rem;
}
.attraction-details-page h2 {
    font-weight: 700;
    font-size: 2.5rem;
}
.expectation-details {
    margin-bottom: 2rem;
}
.expectation-details h4 {
    font-size: 1.2rem;
}
.attraction-footer-price {
    display: flex;
    align-items: center;
}
.attraction-footer-price button {
    background-color: #51CAFA;
    color: #00081d;
    font-weight: 600;
    outline: none;
    border: none;
    box-sizing: none;
    padding: 6px 12px;
    border-radius: 2rem;
}
@media screen and (max-width:1024px){
    .attraction-details-page {
        padding: 1rem;
    }
}
@media screen and (min-width:300px) and (max-width:767px){
    .attraction-details-page-image {
        height: 300px;
    }
}
.att-price-table {
        color: #1713E8;
        padding: 0.5rem;
        border:1.5px solid #26DB26,
}