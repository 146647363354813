*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.banner {
    position: relative;
    padding: 0 2rem;
    height: 100%;
  }
  .myvideo {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 500px;
      object-fit: cover;
  }
  .main-container {
      position: relative;
      z-index: 99;
      padding-top: 5%;
      text-align: center;
  }
 .banner-title {
    font-size: 10em;
    font-family: "Playball", cursive;
    color: #fff;
    line-height: 0.7em;
    text-shadow: 0 0 15px #000;
 }
 .banner-text1 {
    color: #fff;
    font-size: 1.2rem;
    font-family: "Montserrat";
    font-weight: 300;
    margin: 2rem 0px;
 }
 #search-container {
    margin: auto;
    border: 2px solid #fff;
    border-radius: 20px;
    width: 45%;
    height: 40px;
    background: rgba(0, 0, 0, 0.4);
}
.video-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.search-input, .search-button {
   border: none;
   outline: none;
   background: none;
   color: #fff;
}
.video-form .search-input {
    width: 80%;
    padding-left: 1rem;
    padding-bottom: 1rem;
}
.video-form ::placeholder {
    color: #fff;
    font-family: "Montserrat";
    font-size: 16px;
    padding: 0 0 0.8rem 0;
}
.search-button {
    font-size: 16px;
    cursor: pointer;
}
.search-packages-combos-attractions1 {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
}
.search-packages-combos-attractions1 a {
    text-decoration: none;
}
.check-button {
    color: #000;
    background-color: #52ccfc;
    font-family: 'Roboto';
    padding: 5px 15px;
    border-radius: 20px;
    cursor: pointer;
}
.check-button:hover {
    background-color: #0a58ca;
}
.banner-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #001036;
    color: #fff;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    padding: 0 4rempx;
    margin-top: 150px; 
    position:relative;

}
.video-footer {
    display: flex;
    justify-content: center;
    
}
.banner-details p {
    color: #fff;
}
.video-footer-text {
    text-align: center;
    margin-top: 0.2rem;
}

.banner-details {
    margin-top: 6px;
    padding: 0.5rem;
}
.banner-text1 {
    position: relative;
    display: inline-block;
    margin-top: 5px !important;
}
.unveil {
    padding: 2rem 4rem 0 4rem;
}
.check-button-for-mobile {
    display: none;
}
@media screen and (min-width: 300px) and (max-width:767px) {
    .banner {
        height: 350px;
        margin: 0;
        padding: 0;
    }
    .myvideo {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .banner-text1 {
        margin: 1rem;
    }
    .search-packages-combos-attractions1 {
        display: flex;
        flex-wrap: nowrap;
        gap: 0;
    }
    .search-packages-combos-attractions1 a {
        max-width: 10rem;
        overflow: hidden;
        color: #000;
        background-color: #52ccfc;
        font-family: 'Roboto';
        padding: 5px 10px;
        border-radius: 20px;
        cursor: pointer;
    }
    .check-button-for-mobile {
        display: block;
        
    }
    .check-button {
        display: none;
    }
    .banner-bar {
        display: none;
    }
    #search-container {
        width: 90%;
    }
    .unveil {
        position: relative;
        padding: 1rem;
    }
}
@media screen and (min-width:768px) and (max-width:1024px) {
    .unveil {
        padding: 1rem;
    }
    #search-container {
       width: 60%;
    }
    .video-form {
        width: 100%;
    }
    .search-input {
        width: 100%;
    }
    .banner-bar {
        text-align: center;
    }
    .banner-details {
        width: 50%;
    }
}
@media screen and (min-width: 1500px) {
    .banner-bar {
        /* margin-top: 100px;  */
        position:relative;
    }
}  