.feedback-container-main {
    margin: 4rem 4rem 2rem 4rem;
    display: flex;
    margin-bottom: 2rem;
}

.prefeedback {
    position: absolute;
    left: 15%;
    padding-top: 10rem;
    font-size: 2rem;
    cursor: pointer;
}
.nextfeedback {
    position: absolute;
    right: 15%;
    padding-top: 10rem;
    font-size: 2rem;
    cursor: pointer;
}
.feedback {
    padding: 2rem 0;
    margin-bottom: 2rem;
    margin: auto;
}
.feedback-container {
    text-align: center;
    align-items: center;
    font-family: "Montserrat";
}
.feedback-container-parent {
    display: flex;
}
.feedback-container-details {
    width: 100%;
    padding: 0 2rem;
}
.feedback-container-image {
    margin: auto;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    padding-top: 2rem;
}
.feedback-container-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.feedback-container-reviewerName {
    padding: 2rem;
}
.feedback-container-reviewerName h3 {
    font-weight: 700;
    font-size: 1.5rem;
}
.feedback-container-review {
    text-align: center;
    font-family: "Montserrat";
    padding: 0 6rem;
}
.feedback-container-review p {
    color: #5f5f5f;
    text-align: center;
    
}
@media screen and (min-width:300px) and (max-width:768px) {
    .feedback-container-main{
       margin: 2rem;
    }
    .feedback-container-review {
       padding: 0;
    }
}
@media screen and (min-width:769px) and (max-width:1024px){
    .feedback-container-main {
       margin: 1rem;
    }
}
