.category-container {
    padding: 1rem 4rem 1rem 4rem;
}
.category-card-container {
    margin-top: 1.5rem;
    display: flex;
    gap: 1.5rem;
}
.category-card-parent:hover {
    transform: scale(calc(1 + 0.05));
}
.category-card-parent {
    width: 15%;
    box-shadow: 0 0 10px #dedede;
    cursor: pointer;
}
@media screen and (min-width: 300px) and (max-width: 767px) {
    .category-card-container {
        flex-wrap: wrap;
    }
    .category-container {
        padding: 1rem;
    }
    .category-card-parent {
        width: 45%;
    }
    .category-container button {
        display: none;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .category-container {
        padding: 1rem;
    }
    .category-card-parent {
        width: 16%;
    }
    .category-card-container {
        flex-wrap: nowrap;
        gap: 12px;
    }

}
.category-card-image {
    height: 250px;
}
.category-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.category-card {
    height: 50%;
    width: 30%;
}
.category-card-details {
    padding: 1rem;
    cursor: pointer;
    text-align: center;
    background-color: #00081d;
}
.category-card-details h4 {
    overflow: hidden;
    font-size: 1.4rem;
    font-Family: 'Juana', sans-serif;
    max-height: 45px;
    min-height: 45px;
}
.category-card-details p {
    font-size: 0.9rem;
    color: #fff;
    max-height: 45px;
    min-height: 45px;
    margin-bottom: 1rem;
}