
.top-package-container {
    padding: 0;
    margin: 0;
}
.top-package-crousel {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem; 
    padding: 0rem 4rem 1rem 4rem ;
}
.top-package-card-container {
    padding: 0rem 4rem; 
    display: flex;
    gap: 1.48rem;
}
.top-package-card {
    width: 23.5%;
    box-shadow: 0 0 10px #dedede;
    cursor: pointer;
    
}
.top-package-card:hover {
    transform: scale(calc(1 + 0.05));
}

@media screen and (min-width: 300px) and (max-width: 767px) {
    .super-sell-container {
        padding: 0rem;
    }
    .top-package-crousel{
        padding: 1rem;
        padding-bottom: 0;
    }
    .top-package-card-container {
        flex-wrap: wrap;
        padding: 1rem;
        gap: 0;
    }
    .top-package-card {
        width: 100%;
        margin-bottom: 16px;
    }
    .top-package-crousel button {
        display: none;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .top-package-card-container{
        padding: 1rem;
        padding-top: 0;
        gap: 1rem;
    }
    .top-package-crousel {
        padding:0 1rem;
    }
    .super-sell-container {
        padding: 1rem;
    }
    .top-package-card {
        width: 23.4%;
    }
    
}
.top-package-card-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.top-package-card-details {
    padding-left: 1rem;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    cursor: pointer;
}
.top-package-card-details h4 {
    font-family: "Montserrat";
    min-height: 45px;
    max-height: 45px;
    overflow: hidden;
    font-size: 1.2em;
    font-weight: 600;
}
.top-package-card-details p {
    font-family: "Montserrat";
    padding: 0.5rem 0 0 0;
}
.rating-and-ratingicon {
    display: flex;
    margin: 0;
    padding: 0;
}
.rating-and-ratingicon p {
    font-family: "Montserrat";
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    overflow: hidden;
}
.price-arrow {
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.price-arrow p {
    padding: 0;
    margin: 0;
}
.price-arrow b {
    color: #248900;
}