.delicacies {
    font-family: "Montserrat";
    padding: 2rem 4rem;
}
.delicacies-top-header {
    display: flex;
    justify-content: space-between;
}
.delicacies-card-parent {
    display: flex;
    justify-content: space-around;
    gap: 1rem;
    padding-top: 1rem;
}
.delicacies-card {
    width: 32%;
    box-shadow: 0 0 10px #dedede;
    cursor: pointer;
}
.delicacies-card:hover {
    transform: scale(calc(1 + 0.05));
}
@media screen and (min-width:300px) and (max-width:767px) {
    .delicacies-card {
      width: 90%;
    }
    .delicacies-card-parent {
        padding-top: 1rem;
        margin: 0;
        flex-wrap: wrap;
    }
    .delicacies-card-image {
        width: 100%;
    }
    .delicacies-card-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .delicacies {
        padding: 0;
        padding-bottom: 2rem;
    }
    .delicacies-top-header {
        padding: 0 2rem;
    }
    .delicacies-top-header button {
      display: none;
    }
}

.delicacies-card-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
.delicacies-card-location-logo {
    text-align: center;
    padding: 0.5rem;
}
.delicacies-card-logo {
     height: 50px;
}
.delicacies-card-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
@media screen and (min-width:768px) and (max-width:1024px){
    .delicacies-top-header {
       padding: 0rem;
    }
    .delicacies{
       padding: 1rem;
    }
}