.attractionbycategory {
    padding: 2rem 4rem;
}

@media screen and (min-width:768px) and (max-width:1025px) {
    .attractionbycategory {
        padding: 1rem;
    }
    .attraction-details {
        padding: 5px;
    }
    .attraction-card-details-ratings {
        gap: 10px;
    }
}

@media screen and (min-width:300px) and (max-width:767px) {
    .attractionbycategory {
        padding: 1rem;
    }

    .attractionbycategory h2 {
        margin-bottom: 0;
    }
    .attraction-details {
        padding: 5px;
    }
    .attraction-card-details-ratings {
        gap: 10px;
    }
}