.InDubai {
    padding: 0rem 4rem;
    margin-bottom: 2rem;
    font-family: "Montserrat";
}
.attraction-grid-parent {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 0 4rem;
}
.mobile-view {
    display: flex;
    width: 100%;
}
.attraction-grid-type1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr); 
    margin-right: 1rem;
    height: 100%;
}
.attraction-item {
    position: relative;
    height: auto;
    cursor: pointer;
}
.attraction-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.attraction-item2 {
    position: relative;
    height: auto;
    cursor: pointer;
    height: 100%;
}
.attraction-item2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.attraction-item:hover {
    transform: scale(calc(1 + 0.05));
}
.attraction-item2:hover {
    transform: scale(calc(1 + 0.05));
}

@media screen and (min-width:300px) and (max-width:767px) {
    .attraction-item {
        height:auto;
        margin: 0;
    }
    .attraction-grid-parent {
        padding:0 1rem;
        display: block;
    }
    .mobile-view {
        display: flex;
        padding: 2rem 0;
    }
}
@media screen and (min-width:768px) and (max-width:1024px){
   .InDubai {
    padding: 1rem;
    margin: 0;
   }
   .attraction-grid-parent {
    padding: 1rem;
   }
}
