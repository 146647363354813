.login-container {
    padding: 2rem 4rem;
    background-color: #EAEAEA;
    display: flex;
    font-family: "Montserrat";
    height: 100%;
}
.login-image {
    width: 50%;
    height: auto;
}
.login-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.login {
    background-color: #fff;
    padding:2rem 4rem 2rem 4rem;
    width: 50%;
}
.login h4 {
    margin-bottom: 1rem;
    font-weight: 800;
    text-align: center;
}

.login-form label {
    margin-top: 1rem;
}
.login-label {
    margin-bottom: 0;
    padding: 0;
}
.login-input-field {
    outline: none;
    border: 1px solid #142267;
    height: 30px;
    border-radius: 5px;
}
.login-form input {
    width: 100%;
    padding: 0.5rem;
}
.login-form a{
    text-decoration: none;
}
.signin-signup-btn {
    width: 100%;
}
.click-btn {
    width: 50%;
    background-color: #E8E9F0;
    color: #142267;
    outline: none;
    box-shadow: none;
    border: 1px solid black;
    padding: 0.5rem;
    border-top-right-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
}

.signin-btn {
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
  width: 50%;
  background-color: #142267;
  color: #fff;
  outline: none;
  border: 1px solid black;
  box-shadow: none;
  padding: 0.5rem;
}
.signup-btn {
    border-top-right-radius: 0.7rem;
    border-bottom-right-radius: 0.7rem;
    width: 50%;
    background-color: #142267;
    color: #fff;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 0.5rem;
}
.login-btn {
    width: 100%;
    background-color: #142267;
    color: #fff;
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0.5rem;
    border-radius: 0.7rem;
}
.forget-password {
    text-align: right;
    margin-top: 0.5rem;
}
.donot-have-ac {
    text-align: center;
}
@media screen and (min-width:300px) and (max-width:769px) {
    .login-container {
        display: block;
        padding: 1rem;
    }
    .login-image {
        width: 100%;
        height: 40%;
    }
    .login {
        width: 100%;
    }
}