.attraction-banner {
    background: url("https://res.cloudinary.com/ddxawuqwy/image/upload/v1705385616/ndgnte8f8ukssu1tffja.svg") no-repeat center center;
    background-size: cover;
    color: #fff;
    height: 400px;
    display: flex;
    justify-content: center;
}
.attraction-banner-title {
    width: 100%;
    margin: auto;
}
.attraction-banner-title h3 {
    text-align: center;
    margin-bottom: 2rem;
}
.attraction-container {
    padding: 2rem 4rem;
    font-family: "Montserrat";
}
.attraction-container h3 {
    max-height: 60px;
    min-height: 60px;
    overflow: hidden;
    margin-bottom: 1rem;
}
.attraction-card-parent {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.attraction-card {
 width: 32%;
 box-shadow: 0 0 10px #dedede;
 cursor: pointer;
}
.attraction-card:hover {
    transform: scale(calc(1 + 0.05));
}
@media screen and (min-width:300px) and (max-width:767px) {
    .attraction-banner {
        height: 300px;
    }
    .attraction-card {
        width: 100%;
    }
    .attraction-container {
        padding: 1rem;
    }
    .attraction-card-details {
        padding: 5px;
    }
    .attraction-details {
        padding: 5px;
    }
    .attraction-details p {
        font-size: 0.5rem;
    }   
}
@media screen and (min-width:768px) and (max-width:1024px){
    .attraction-card {
        width: 48%;
    }
    .attraction-container {
        padding: 0.5rem;
    }
}

.attraction-card-image {
  width: 100%;
}
.attraction-card-image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.attraction-card-details {
    padding: 1rem 0;
    cursor: pointer;
}
.attraction-card-details h3 {
    padding-left: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: "Montserrat";
}
.attraction-card-details-ratings {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}
.attraction-details {
    border: 1px solid #52CCFC;
    color: #52CCFC;
    border-radius: 20px;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    color: #00081d;
}