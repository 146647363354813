.shopingstops-container {
    padding: 0;
    margin: 0;
}
.shopingstop-image {
    height: 410px;
    width: 100%;
}
.shopingstop-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.shopingstops-description {
    padding: 2rem 4rem;
}
.shoping-location {
    padding: 1rem 0;
    font-size: 1rem 
}
.shoping-location p {
    font-size: 1.2rem;
}
.shoping-overview p {
  font-size: 1.2rem;
}

/* --------------------------------Loader------------------------------  */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
  }
  
  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db; 
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  