.navbar {
    height: 4rem;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 999;
    align-items: center;
}
.navbar-container {
    padding:0 4rem;
    margin:0;
    display: flex; 
    justify-content: space-between;
}
.logo-image {
    height: 4rem;
}
.ml-auto {
    margin-left: auto;
}
.list {
    margin-left: 1rem;
    font-family: 'Roboto';
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 66px;
}
.active {
    color: black;
    border-bottom: 4px solid #00081d;
}
.navbar-login-btn {
    background-color: #52CCFC;
    color: #00081d;
    padding: 2px 16px 0px 16px !important;
    border-radius: 2rem;
    font-family: "Montserrat";
    height: 1.8rem;
    margin: auto;
    margin-left: 1.6rem;
}
.navbar-login-btn.active {
    border: none;
}
.navbar-login-btn:hover {
    background-color: #142267;
    color: #52ccfc !important;
}

.navbar-login-btn-mobile {
    width: auto;
    margin: auto;
}
@media screen and (min-width:769px) and (max-width: 1023px) {
    .logo-image {
        width: 10rem;
    }
    .navbar {
        height: auto;
        padding: 0;
        margin: 0;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 999;
    }
    .navbar-container {
     padding: 0 1rem;
    }
    .navbar-login-btn{
        width: 5rem;
        margin-left: 1rem;
        margin-bottom: 1rem;
    }
    .navbar-login-btn-mobile {
        width: 10rem;
        margin: 0;
        padding-top: 0.8rem;
        background-color: #fff;
    }
    .list {
        display: block;
        margin: 0;
        padding: 0;
        width: 10rem;
        height: 100vw;
        background-color: #fff;
        padding: 5px 1.5rem;
    }
    .navbar-nav {
        height: 1.5rem;
        background-color: #fff;
    }
    .active {
        border-bottom-width: 2px;
        border-bottom: 2px solid black;
    }
}
@media screen and (min-width:300px) and (max-width: 768px) {
    .logo-image {
        width: 8rem;
        margin-right: 0;
    }
    .navbar {
        height: auto;
        padding: 0;
        margin: 0;
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 999;
    }
    .navbar-container {
     padding: 0 1rem;
    }
    .navbar-login-btn{
        width: 5rem;
        margin-left: 1rem;
        margin-bottom: 1rem;
    }
    .navbar-login-btn-mobile {
        width: 10rem;
        margin: 0;
        background-color: #fff;
    }
    .list {
        display: block;
        margin: 0;
        padding: 0;
        width: 10rem;
        height: 100vw;
        background-color: #fff;
        padding: 5px 1.5rem;
    }
    .navbar-nav {
        height: 1.5rem;
        background-color: #fff;
    }
    .active {
        border-bottom-width: 2px;
    }
}
/* ------------------------- */
