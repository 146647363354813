.delicacies-banner {
    font-family: "Montserrat";
    height: 400px;
}
.delicacies-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.delicacies-details {
    padding: 2rem 4rem;
}
.delicacies-details h3 {
    margin-bottom: 2rem;
    font-weight: 600;
    color: #00081d;
}
.delicacies-opening-hours h4 {
    font-weight: 600;
    color: #00081d;
    font-size: 1.2rem;
}
.delicacies-opening-hours p {
    font-size: 1rem;
}
.delicacies-details-description p{
    margin-bottom: 1rem;
}
/* -------------------------------- Loader css */
  