.shopingstops-conatiner {
    padding: 2rem 4rem;
}
.shopingstops-top1 {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}
.shopingstops-card-parent {
    display: flex;
    gap: 1rem;
}
.shopingstops-card {
    width: 32.4%;
    box-shadow: 0 0 10px #dedede;
    cursor: pointer;
}
.shopingstops-card:hover {
    transform: scale(calc(1 + 0.05));
}
@media screen and (min-width:767px) and (max-width:1024px){
    .shopingstops-conatiner {
        padding: 1rem;
    }

}
.shopingstops-card-image {
    width: 100%;
    height: 300px;
    position: relative;
    
}
.shopingstops-card-image p{
    position: absolute;
    bottom: -2px;
    padding: 0.5rem 1rem;
    background-color: #fff;
    border-top-right-radius: 5px;
}
.shopingstops-card-image img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}
.shopingstops-logo {
    width: 100%;
    height: 4rem;
    padding: 1rem;
    text-align: center;
}
.shopingstops-logo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}
/* ------------------------------------------------Shoping stops in dubai 4cards----------- */
.shopingstops-top {
    display: flex;
    justify-content: space-between;
    padding: 1rem 4rem;
}
.shoping-stops-card4-parent {
    display: flex;
    padding: 0 4rem;
    gap: 15px;
}
.shoping-stops-card4 {
    width: 24%;
    box-shadow: 0 0 10px #dedede;
    cursor: pointer;
}
.shoping-stops-card4:hover {
    transform: scale(calc(1 + 0.05));
}

.shoping-stops-card4-image {
    width: 100%;
    height: 200px;
    position: relative;
}
.shoping-stops-card4-image p{
    position: absolute;
    bottom: -2px;
    padding: 0.5rem 1rem;
    background-color: #fff;
    border-top-right-radius: 5px;
}
.shoping-stops-card4-image img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}
.shoping-stops-card4-details {
    text-align: center;
    padding: 1rem;
}
.shoping-stops-card4-details h5 {
    font-size: 1rem;
    font-weight: 600;
}
@media screen and (min-width:768px) and (max-width:1024px){
   .shopingstops-top {
    padding: 1rem;
  }
  .shoping-stops-card4-parent {
    padding: 0 1rem 1rem 1rem;
  }
}
@media screen and (min-width:300px) and (max-width:767px){
  .shopingstops-conatiner {
    padding: 1rem;
  }
  /* .shopingstops-top1 h4 {
    width: 60%;
  } */
  .shopingstops-top1 p {
    display: none;
  }
  .shopingstops-top p {
      display: none;
  }
  .shopingstops-card-parent {
    flex-wrap: wrap;
    gap: 0;
  }
  .shopingstops-card {
    width: 100%;
    margin-bottom: 16px;
  }
  .shopingstops-card-image p {
    bottom: -2px;
  }
  .shoping-stops-card4-image p {
    bottom: -2px;
  }
  .shopingstops-top {
    padding: 1rem;
  }
  .shoping-stops-card4-parent {
    padding: 1rem;
    flex-wrap: wrap;
  }
  .shoping-stops-card4 {
    width: 45%;
  }
}