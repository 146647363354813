.landcombos-banner {
    width: 100%;
    height: 410px;
}
.landcombos-banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.landcombos-details {
    padding: 2rem 4rem;
    margin-bottom: 4rem;
}
@media screen and (max-width:1024px){
    .landcombos-details {
        padding: 1rem;
    }
}
.landcombos-details h2 {
    margin-bottom: 1rem;
    font-weight: 800;
    font-size: 1.5rem;
}
.landcombos-details h4 {
    font-size: 1rem;
    font-weight: 800;
}