.package-banner {
    background: url('https://res.cloudinary.com/ddxawuqwy/image/upload/v1705385616/ndgnte8f8ukssu1tffja.svg') no-repeat center center;
    background-size: cover;
    color: #fff;
    height: 400px;
}
.main-container {
    position: relative;
    z-index: 99;
    padding-top: 5%;
    text-align: center;
}
.banner-title {
  font-family: "Playball", cursive;
  color: #fff;
  font-size: 6rem;
  text-shadow: 0 0 15px #000;
}
.banner-text {
  color: #fff;
  text-shadow: 0 1px 3px #000;
  font-size: 1.2rem;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 2rem 0px;
}
#search-container2 {
  margin: auto;
  border: 2px solid #fff;
  border-radius: 20px;
  width: 45%;
  text-align: center !important;
  height: 40px;
  padding-bottom: 2rem;
}
#search-container2 form {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
#search-container2 .search-input, .search-button {
 border: none;
 outline: none;
 background: none;
 color: #fff;
 padding: 0.4rem 1rem 1rem 0.6rem;
}
#search-container2 .search-input {
  width: 90%;
}
#search-container2 ::placeholder {
  color: #fff;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
}
#search-container2 .search-button {
  font-size: 16px;
  cursor: pointer;
}
.search-packages-combos-attractions {
  margin-top: 2rem;
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 1rem;
}
.search-packages-combos-attractions a {
    text-decoration: none;
}
.check-button {
  color: #000;
  background-color: #52ccfc;
  font-family: 'Roboto';
  padding: 5px 15px;
  border-radius: 20px;
  cursor: pointer;
}
.check-button:hover {
  background-color: #0a58ca;
}
.top-package-card-container1 {
  padding: 0rem 4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.top-package-card-container1 .top-package-card {
  width: 32%;
}
@media screen and (min-width:300px) and (max-width:767px){
  .package-banner {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .top-package-card-container1 {
     flex-wrap: wrap;
     padding: 1rem;
     gap: 0;
  }
  .top-package-card-container1 .top-package-card {
    width: 100%;
  }
  .InDubai {
    padding: 1rem;
    margin: 0;
  }
}
@media screen and (min-width:768px) and (max-width:1024px){
   .top-package-card-container1 {
    padding: 1rem;
   }
   .top-package-card-container1 .top-package-card {
         width: 48%;
   }
}