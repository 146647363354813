.divStyled {
    width: 100%;
    height: 410px;
}
.divStyled img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.image-footer {
    padding: 1rem;
    background-color: #001036;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: space-around;
}

.image-footer p {
    color: #fff;
}
.image-footer-logo-duration {
    display: flex;
    justify-content: center;
}
.image-footer-duration {
    padding-top: 2px;
}
.packageDetails-container {
    padding: 2rem 4rem;
    font-family: "Montserat";
}

.packageDetails-container h2 {
    margin-bottom: 1rem;
}
.packageDetails-container h3 {
     font-weight: 600;
}
.inclusion {
    background: #DEFFC3 url('https://res.cloudinary.com/ddxawuqwy/image/upload/v1710241157/inc_exc_bg_raxhhz.png') bottom right no-repeat;
    padding: 1.5rem;
}
.exclusion {
    background: #FFE8E8 url('https://res.cloudinary.com/ddxawuqwy/image/upload/v1710241157/inc_exc_bg_raxhhz.png')bottom right no-repeat;
    padding: 1.5rem;
}
.booking-procedure ul {
    padding-left: 2rem;
}
/* ------------------------Hotel Css-------------------------- */
.hotel-card {
    box-shadow: 0 0 10px #dedede;
    background-color: #B8F3FC;
    padding: 1rem 2rem 2rem 2rem;
    width: 100%;
    border-radius: 0.8rem;
}
.hotel-image {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 310px;
}
.hotel-image img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}
.hotel-details  h4{
    padding: 1rem 0;
    font-family: "Montserrat";
    font-weight: 700;
    color: #0E7686;
}
.hotel-details h5 {
    color: #0E7686;
    font-family: "Montserrat";
}
.hotel-details p {
    margin-bottom: 2rem;
    color: #0E7686;
}
.hotel-details a {
    text-decoration: none;
    font-family: "Montserrat";
    background-color: #00081d;
    font-weight: 600;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    
}
.hotel-details a:hover {
    font-weight: 600;
    background-color: #687eb8;
    color: #00081d;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
}
.notes {
    /* background-color: #FFE8E8; */
    background: #FFE8E8 url('https://res.cloudinary.com/ddxawuqwy/image/upload/v1710241157/inc_exc_bg_raxhhz.png')bottom right no-repeat;

    padding: 1rem;
}
.must-carry {
    margin-bottom: 4rem;
}
.must-carry ul {
    padding-left: 2rem;
}
.footer {
    padding: 0.5rem 4rem;
    background:#001036 url('https://res.cloudinary.com/ddxawuqwy/image/upload/v1710241157/inc_exc_bg_raxhhz.png') bottom center repeat-x;;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    transition: bottom 0.3s ease;
    align-items: center;
}

.footer-hidden {
    bottom: -200px; 
  }
.footer p {
    color: #fff;
}
.footer-price {
    align-items: center;
    justify-content: center;
    padding-top: 0.5rem;
}
#more-price-btn {
    border: none;
    border-radius: 50%;
    padding: 0 6px;
    padding-bottom: 3px;
    margin-left: 10px;
    background-color: #eaeaea;
}
#checkbox {
    border-radius: 50%;
}

/* ----------------------------------- More Price ------------- */
.morePrice {
    margin-top: 1rem;
    position: absolute;
    bottom: 3.2rem;
    left: 20%;
    background-color: #eaeaea;
    box-shadow: 0 0 10px #3d3939;
}
.morePriceNone {
    display: none;
}

.footer p span{
    margin: 0;
    padding: 0;
    color: #FADD26;
    
}
.footer p button {
    background-color: #51CAFA;
    color: #00081d;
    font-weight: 600;
    outline: none;
    border: none;
    box-sizing: none;
    padding: 6px 12px;
    border-radius: 2rem;
}
.notes ul {
    list-style-type: disc;
}
.notes li {
     margin-bottom: 0.5rem;
}
.packege-details {
    background-color: rgba(0, 0, 0, 0.6); 
    z-index: 0;
    .inclusion {
        background: none;
    }
    .exclusion {
        background: none;
    }
    .hotel-card{
        background: none;
    }
    .notes {
        background: none;
    }
}
.footer-more-btn {
    display:flex;
    justify-content:space-between;
    align-items:center;
}
.footer-more-desktop {
   display: block;
}
.footer-more-tab-mob {
    display: none;
}
@media screen and (min-width:300px) and (max-width:767px){
    .divStyled {
        height: 300px;
    }
    .image-footer {
        padding: 1rem;
        display: none;
    }
    .packageDetails-container {
        padding: 1rem;
    }
    .footer {
        padding: 1rem;
    }
    .footer-more-tab-mob {
        display: none;
    }
    .footer-more-desktop {
        display: none;
    }
}
@media screen and (min-width:768px) and (max-width:1024px){
    .packageDetails-container {
        padding: 1rem;
    }
    .footer {
        padding: 1rem;
    }
    .footer-more-tab-mob {
        display: block;
    }
    .footer-more-desktop {
        display: none;
    }
}